import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildExportTemplateUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { ExportTemplate } from '../types';

export const getExportTemplates = (
    actionName?: string,
    processResultFunction?: Function,
): Observable<{
    result: ExportTemplate[];
    actionName?: string;
    processResultFunction?: Function;
}> => {
    const url = buildExportTemplateUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ ExportTemplate: any[] }>) => {
            const result = convertToCamel<ExportTemplate[]>(response.ExportTemplate);
            return observableOf({
                result,
                actionName,
                processResultFunction,
            });
        }),
    );
};
